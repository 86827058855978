import React from 'react';
import { Grid } from '@mui/material';
import AdditionalServices from '../AdditionalServices';

const Step3 = () => {
  return (
    <Grid container spacing={2}>
      <AdditionalServices />
    </Grid>
  );
};

export default Step3;
