import { TrainClass, TrainRoute } from '../../types/trains';

export const OPTIONS = {
  YES: 'Yes',
  NO: 'No',
};

export const TOURIST_TYPES = {
  TOURIST: 'Tourist',
  AGENCY: 'Agency',
};

export const INCREASE_RATES_FOR_TOURISTS = 10;
export const INCREASE_RATES_FOR_AGENCY = 5;

export const VISA_FEE_INDIVIDUAL = 90;
export const VISA_FEE_GROUP = 40;

export const VISA_TYPES = {
  INDIVIDUAL: 'Individual',
  GROUP: 'Group',
};

export const visaOptions = [OPTIONS.YES, OPTIONS.NO];

export const steps = [
  "Choose Tourist's Type",
  'Basic Details',
  'Choose Hotel',
  'Additional Services',
  'Calculate',
  'Tourist Places',
];

export const TRAIN_CLASSES = {
  ECONOMY: 'Economy',
  BUSINESS: 'Business',
};

export const ROOM_SIZES = {
  SINGLE: 'Single',
  DOUBLE: 'Double',
};

export const HOTEL_CITIES = {
  TASHKENT: 'tashkent',
  SAMARKAND: 'samarkand',
  BUKHARA: 'bukhara',
  KHIVA: 'khiva',
};

export const TASHKENT_HOTEL_RATES = {
  hyattRegency: {
    standardKing: {
      single: 190,
      double: 225,
    },
    deluxeKing: {
      single: 215,
      double: 250,
    },
    clubKing: {
      single: 235,
      double: 270,
    },
  },
  hilton: {
    guestRoom: {
      single: 210,
      double: 240,
    },
    executiveRoom: {
      single: 260,
      double: 295,
    },
    cornerSuite: {
      single: 401,
      double: 435,
    },
  },
  wyndham: {
    standard: {
      single: 120,
      double: 145,
    },
    deluxe: {
      single: 125,
      double: 150,
    },
  },
  hampton: {
    superFlex: {
      single: 115,
      double: 155,
    },
    queenKing: {
      single: 125,
      double: 165,
    },
    deluxe: {
      single: 165,
      double: 200,
    },
  },
  ramada: {
    standard: {
      single: 95,
      double: 120,
    },
    premium: {
      single: 130,
      double: 160,
    },
  },
  gabrielle: {
    standard: {
      single: 75,
      double: 105,
    },
    panoramic: {
      single: 95,
      double: 125,
    },
    royal: {
      single: 115,
      double: 145,
    },
    apartment: {
      single: 135,
      double: 165,
    },
  },
  novza: {
    standard: {
      single: 75,
      double: 95,
    },
    deluxe: {
      single: 80,
      double: 105,
    },
    superior: {
      single: 90,
      double: 110,
    },
  },
  mercure: {
    superiorKing: {
      single: 116,
      double: 145,
    },
    superior: {
      single: 145,
      double: 145,
    },
    prestige: {
      single: 155,
      double: 185,
    },
  },
  garnet: {
    standard: {
      single: 70,
      double: 85,
    },
    deluxe: {
      single: 80,
      double: 95,
    },
    triple: {
      single: 140,
      double: 145,
    },
    suite: {
      single: 140,
      double: 145,
    },
  },
  greenPark: {
    standard: {
      single: 85,
      double: 110,
    },
    juniorSuite: {
      single: 96,
      double: 122,
    },
    executiveSuite: {
      single: 155,
      double: 170,
    },
  },
  stayInn: {
    standard: {
      single: 50,
      double: 70,
    },
    superior: {
      single: 70,
      double: 90,
    },
  },
  krokus: {
    standard: {
      single: 75,
      double: 120,
    },
    deluxe: {
      single: 85,
      double: 130,
    },
    studio: {
      single: 100,
      double: 150,
    },
  },
  dsfGarden: {
    standard: {
      single: 50,
      double: 70,
    },
    triple: {
      single: 105,
      double: 130,
    },
  },
  alAnvarHalol: {
    standard: {
      single: 55,
      double: 80,
    },
  },
  uzbekistan: {
    standard: {
      single: 42,
      double: 57,
    },
  },
  shamsan: {
    standard: {
      single: 52,
      double: 65,
    },
  },
  wyndhamGarden: {
    standard: {
      single: 65,
      double: 90,
    },
  },
  international: {
    standard: {
      single: 120,
      double: 147,
    },
  },
  rayyan: {
    standard: {
      single: 70,
      double: 90,
    },
  },
};

export const SAMARKAND_HOTEL_RATES = {
  movenPick: {
    standard: {
      single: 130,
      double: 155,
    },
    executive: {
      single: 200,
      double: 225,
    },
  },
  alexander: {
    standardSingle: {
      single: 80,
      double: 80,
    },
    standardDouble: {
      single: 100,
      double: 100,
    },
    standardTwin: {
      single: 120,
      double: 120,
    },
    standardTriple: {
      single: 160,
      double: 160,
    },
    luxTwin: {
      single: 140,
      double: 140,
    },
  },
  dilimahPremium: {
    standard: {
      single: 80,
      double: 130,
    },
    superior: {
      single: 95,
      double: 130,
    },
  },
  diyora: {
    standard: {
      single: 70,
      double: 105,
    },
    triple: {
      single: 135,
      double: 135,
    },
  },
  malikaPrime: {
    standard: {
      single: 65,
      double: 95,
    },
    triple: {
      single: 135,
      double: 135,
    },
  },
  malikaClassic: {
    standard: {
      single: 55,
      double: 90,
    },
    triple: {
      single: 120,
      double: 120,
    },
  },
  orientStar: {
    standard: {
      single: 65,
      double: 95,
    },
    lux: {
      single: 80,
      double: 115,
    },
    family: {
      single: 130,
      double: 130,
    },
  },
  qosh: {
    standard: {
      single: 80,
      double: 115,
    },
    triple: {
      single: 150,
      double: 150,
    },
  },
  dilshoda: {
    standard: {
      single: 55,
      double: 80,
    },
    triple: {
      single: 105,
      double: 105,
    },
  },
  wellfort: {
    standard: {
      single: 80,
      double: 115,
    },
    triple: {
      single: 140,
      double: 140,
    },
  },
  bravo: {
    standard: {
      single: 60,
      double: 90,
    },
  },
  eastStar: {
    standard: {
      single: 75,
      double: 110,
    },
    deluxe: {
      single: 80,
      double: 115,
    },
  },
  jahongirPremium: {
    standard: {
      single: 40,
      double: 60,
    },
  },
  asiaSamarkand: {
    standard: {
      single: 45,
      double: 65,
    },
  },
  hiltonGarden: {
    standard: {
      single: 165,
      double: 170,
    },
  },
  hiltonGardenInn: {
    standard: {
      single: 85,
      double: 120,
    },
  },

  hiltonSamarkandRegency: {
    standard: {
      single: 170,
      double: 180,
    },
  },
  rayyan: {
    standard: {
      single: 70,
      double: 80,
    },
    triple: {
      single: 85,
      double: 85,
    },
  },
};

export const BUKHARA_HOTEL_RATES = {
  malikaBukhara: {
    standard: {
      single: 80,
      double: 100,
    },
    triple: {
      single: 130,
      double: 130,
    },
  },
  gardenPlaza: {
    standard: {
      single: 55,
      double: 85,
    },
    triple: {
      single: 125,
      double: 125,
    },
    deluxe: {
      single: 125,
      double: 125,
    },
  },
  mercure: {
    classic: {
      single: 125,
      double: 145,
    },
    superior: {
      single: 135,
      double: 155,
    },
    privilege: {
      single: 160,
      double: 180,
    },
  },
  omarKhayam: {
    standard: {
      single: 80,
      double: 100,
    },
    triple: {
      single: 130,
      double: 130,
    },
  },
  paradise: {
    standard: {
      single: 80,
      double: 110,
    },
    triple: {
      single: 155,
      double: 155,
    },
    deluxe: {
      single: 85,
      double: 125,
    },
  },
  royal: {
    standard: {
      single: 65,
      double: 95,
    },
    triple: {
      single: 145,
      double: 145,
    },
  },
  shahidZarafshan: {
    standard: {
      single: 75,
      double: 95,
    },
    deluxe: {
      single: 100,
      double: 135,
    },
  },
  turonCity: {
    standard: {
      single: 60,
      double: 85,
    },
    deluxe: {
      single: 115,
      double: 155,
    },
  },
  wyndham: {
    standard: {
      single: 95,
      double: 120,
    },
    deluxe: {
      single: 250,
      double: 250,
    },
  },
  dunyo: {
    standard: {
      single: 50,
      double: 75,
    },
    triple: {
      single: 100,
      double: 100,
    },
  },
  gumbaz: {
    standard: {
      single: 40,
      double: 60,
    },
    triple: {
      single: 110,
      double: 110,
    },
  },
  lyabi: {
    standard: {
      single: 50,
      double: 80,
    },
  },
  hiltonSamarakand: {
    standard: {
      single: 50,
      double: 70,
    },
  },
  asiaBukhara: {
    standard: {
      single: 100,
      double: 120,
    },
  },
  lyabiHouse: {
    standard: {
      single: 60,
      double: 80,
    },
  },
  rayyan: {
    standard: {
      single: 45,
      double: 60,
    },
    triple: {
      single: 70,
      double: 70,
    },
  },
};

export const KHIVA_HOTEL_RATES = {
  asia: {
    standard: {
      single: 65,
      double: 85,
    },
    triple: {
      single: 145,
      double: 145,
    },
  },
  bankir: {
    standard: {
      single: 65,
      double: 95,
    },
    triple: {
      single: 120,
      double: 120,
    },
  },
  orientStar: {
    standard: {
      single: 65,
      double: 75,
    },
    triple: {
      single: 130,
      double: 130,
    },
  },
};

export const SPEED_TRAIN_RATES: Record<
  TrainRoute,
  Record<TrainClass, number>
> = {
  tashkentToSamarkand: {
    economy: 30,
    business: 40,
  },
  tashkentToBukhara: {
    economy: 40,
    business: 60,
  },
  samarkandToBukhara: {
    economy: 30,
    business: 40,
  },
  samarkandToTashkent: {
    economy: 30,
    business: 40,
  },
  bukharaToTashkent: {
    economy: 40,
    business: 60,
  },
  bukharaToSamarkand: {
    economy: 30,
    business: 40,
  },
};

export const REGULAR_TRAIN_RATES: Record<
  TrainRoute,
  Record<TrainClass, number>
> = {
  tashkentToSamarkand: {
    economy: 20,
    business: 30,
  },
  tashkentToBukhara: {
    economy: 25,
    business: 40,
  },
  samarkandToBukhara: {
    economy: 20,
    business: 30,
  },
  samarkandToTashkent: {
    economy: 20,
    business: 30,
  },
  bukharaToTashkent: {
    economy: 25,
    business: 40,
  },
  bukharaToSamarkand: {
    economy: 20,
    business: 30,
  },
};

export const GUIDE_RATE = 70;

export const CAR_RATES = {
  oneDay: 60,
  mountain: 100,
  airport: 20,
};

export const MINI_BUS_RATES = {
  oneDay: 100,
  mountain: 150,
  airport: 30,
};
