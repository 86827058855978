import React from 'react';
import SocialLinks from '../SocialLinks';
import PlanTour from '../PlanTour';
import ApplyVisa from '../ApplyVisa';

const Footer = () => {
  return (
    <div className="apply-visa">
      <SocialLinks />
      <PlanTour />
      <ApplyVisa />
    </div>
  );
};

export default Footer;
