export const touristPlaces = [
  {
    category: 'Tashkent',
    places: [
      'Mausoleum of Kafal Shashi, a mystical poet and interpreter of Koran of the 10th century.',
      'Barakhan Madrassah, which accommodates the Clerical Board of Central Asian Moslems, and its famous Library, with over 30.000 books, 3500 manuscripts and the precious sample of the Holy Koran, written by the fourth Caliph Osman and bearing stains of his blood on its pages.',
      'One of the most ancient bazaars of Tashkent Chorus.',
      'Amir Timor Square and museum.',
      'Broadway street with artist and souvenirs.',
      'Independence Square.',
      'Victory Monument, National Library and Square with Fountains.',
      'Memorial of Repressions with traditional ornaments.',
      'TV Tower from outside.',
      'Monument of Courage.',
    ],
  },
  {
    category: 'Samarkand',
    places: [
      'Mausoleum of Imam Al -Bukhariy.',
      'Registan Square with three madrassahas.',
      'Shakhi Zinda necropolis.',
      'The Mausoleum of Qusam Ibn-Abbas.',
      'Visit to Denial Mausoleum.',
    ],
  },
  {
    category: 'Bukhara',
    places: [
      'Ark (1-5th cent.).',
      'Bolo Khauz Mosque.',
      'Mausoleum of Ismail Samani.',
      'Chashmai Ayub.',
      'Poyi Kalon Ensemble.',
      'Madrassah of Ulugbek.',
      'Madrassah of Abdulazizkhan.',
      'Magoki Attori Mosque.',
      'Lyabi Khauz Complex.',
      'Khodja Abdul Khalik Gijduvani.',
      'Khodja Muhammad Orif Rigvari.',
      'Khodja Makhmud Anjir Fagnavi.',
      'Khodja Azizan Ali Ar Romitani.',
      'Khodja Muhammad Baba As-Sammasi.',
      'Khodja Said Amir Al Kulal.',
      'Imamu Tarikatti Shakh Muhammad Bahau-d Din An Nakshbandi.',
    ],
  },
  {
    category: 'Khiva',
    places: [
      'Kalta Minor Minaret.',
      'Muhammad Amin-khan Madrasah.',
      'Islam Khodja Minaret.',
      'Juma Mosque.',
      'The Walls of Itchan Kala.',
      'Tash-Hauli Palace.',
      'Mahmud Pahlavan Mausoleum.',
      'The Watchtower of Kunya-Ark Citadel.',
      'Khiva Silk Carpet Workshop.',
      'Allakuli Khan Madrasah.',
    ],
  },
];
